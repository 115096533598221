<template>
  <validation-observer ref="formPromo">
    <form
      @submit.prevent="save"
    >
      <div class="animated fadeIn">
        <b-card class="px-md-2">
          <ResponseAlert ref="response" />
          <h3>{{ $route.meta.breadcrumb[1].text }}</h3>
          <b-row class="mt-2">
            <b-col
              md="9"
            >
              <!-- Field Nama Promo -->
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="promo_name"
                  >Nama Promo</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Nama promo"
                    rules="required"
                  >
                    <b-form-input
                      id="promo_name"
                      v-model="models.name"
                      :disabled="isDetailPage"
                      type="text"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <!-- //////////////// -->

              <!-- Field Kode Promo -->
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="kode_promo"
                  >Kode Promo</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Kode promo"
                    rules="required"
                  >
                    <b-form-input
                      id="kode_promo"
                      v-model="models.voucherCode"
                      :disabled="isDetailPage"
                      type="text"
                      :state="errors.length > 0 ? false:null"
                      @keydown.space.prevent
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <!-- //////////////// -->

              <!-- Field Tipe Promo -->
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                  >Tipe Promo</label>
                </b-col>
                <b-col
                  v-if="isDetailPage"
                  sm="8"
                >
                  <b-form-input
                    :value="masters.promo_types.find(type => type.value === models.promoType).text"
                    disabled
                  />
                </b-col>
                <b-col
                  v-else
                  sm="7"
                >
                  <div

                    class="col-sm-12 d-flex justify-content-between"
                  >
                    <label
                      v-for="type, index in masters.promo_types"
                      :key="index"
                      class="radio-inline"
                    >
                      <input
                        v-model="models.promoType"
                        type="radio"
                        :value="type.value"
                        name="promo_type"
                      >
                      <span class="font-weight-bold text-capitalize">{{ type.text }}</span>
                    </label>
                  </div>
                </b-col>
              </b-row>
              <!-- //////////////// -->

              <!-- Field Pilih Produk -->
              <b-row
                v-if="models.promoType === 3"
                class="my-1"
                align-v="center"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="product"
                  >Pilih Produk</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Produk"
                    rules="required"
                  >
                    <b-form-input
                      v-if="isDetailPage"
                      :value="models.products.map(product => product.id).join(', ')"
                      disabled
                    />
                    <v-select
                      v-else
                      id="tag"
                      v-model="models.products"
                      :close-on-select="false"
                      clearable
                      label="name"
                      placeholder="- Pilih Produk -"
                      :state="errors.length > 0 ? false:null"
                      multiple
                      :options="masters.products"
                      :reduce="options => options.id"
                      @search="debounceSearch"
                    >
                      <template #no-options="{ search, searching, loading }">
                        Tidak ditemukan hasil pencarian
                      </template>
                      <template
                        slot="selected-option"
                        slot-scope="option"
                      >
                        <span>{{ option.name }} - {{ option.unitPriceFormat }}</span>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <!-- //////////////// -->

              <div class="d-flex flex-column">
                <b-row
                  v-if="models.promoType === 3"
                  class="my-1"
                  align-v="baseline"
                >
                  <b-col sm="4">
                    <label
                      class="h5"
                    >Diskon Dalam Bentuk</label>
                  </b-col>
                  <b-col
                    sm="8"
                  >
                    <div class="col-sm-9 mb-1 d-flex justify-content-between">
                      <label
                        v-for="type, index in discount_types"
                        :key="index"
                        class="radio-inline"
                      >
                        <input
                          v-model="vars.discount_type"
                          type="radio"
                          :value="type"
                          name="discount_type"
                        >
                        <span class="font-weight-bold text-capitalize">{{ type }}</span>
                      </label>
                    </div>
                    <div v-if="vars.discount_type === 'Harga Setelah Diskon'">
                      <validation-provider
                        #default="{ errors }"
                        name="Harga Setelah Diskon"
                        rules="required|numeric"
                      >
                        <b-form-input
                          v-model="models.discountPrice"
                          :disabled="isDetailPage"
                          type="number"
                          :state="errors.length > 0 ? false:null"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                  </b-col>
                </b-row>
                <!-- //////////////// -->
                <!-- Field Minimal Pembelian -->
                <b-row
                  :class="{'order-2': models.promoType === 3}"
                  class="my-1"
                  align-v="baseline"
                >
                  <b-col sm="4">
                    <label
                      class="h5"
                      for="minimal_pembelian"
                    >Minimal Pembelian</label>
                  </b-col>
                  <b-col
                    v-if="isDetailPage"
                    sm="8"
                  >
                    <b-form-input
                      :value="vars.minimumPaymentType ? parseFloat(models.minimumPayment).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') : 'Tidak Ada'"
                      disabled
                    />
                  </b-col>
                  <b-col
                    v-else
                    sm="8"
                  >
                    <div class="col-sm-7 mb-1 d-flex justify-content-between">
                      <label
                        class="radio-inline"
                      >
                        <input
                          v-model="vars.minimumPaymentType"
                          type="radio"
                          :value="true"
                          name="minimal_purchase"
                        >
                        <span class="font-weight-bold text-capitalize">Tulis Nominal</span>
                      </label>
                      <label
                        class="radio-inline"
                      >
                        <input
                          v-model="vars.minimumPaymentType"
                          type="radio"
                          :value="false"
                          name="minimal_purchase"
                        >
                        <span class="font-weight-bold text-capitalize">Tidak Ada</span>
                      </label>
                    </div>
                    <div v-if="vars.minimumPaymentType">
                      <validation-provider
                        #default="{ errors }"
                        name="Minimal pembelian"
                        rules="required|numeric"
                      >
                        <b-form-input
                          id="minimal_pembelian"
                          v-model="models.minimumPayment"
                          :disabled="isDetailPage"
                          type="number"
                          :state="errors.length > 0 ? false:null"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                  </b-col>
                </b-row>
                <!-- //////////////// -->
                <!-- Field Persentase Diskon -->
                <b-row
                  v-if="showDiscountPercentage"
                  :class="{'order-1': models.promoType === 3}"
                  class="my-1"
                >
                  <b-col sm="4">
                    <label
                      class="h5"
                      for="persentase_diskon"
                    >Persentase Diskon (%)</label>
                  </b-col>
                  <b-col sm="8">
                    <div class="col-sm-10 mb-1 d-flex justify-content-between">
                      <label
                        v-for="type, index in masters.discount_percentages"
                        :key="index"
                        class="radio-inline"
                      >
                        <input
                          v-model="vars.discountPercentage"
                          type="radio"
                          :value="type"
                          name="discount_percentage"
                        >
                        <span class="font-weight-bold text-capitalize">{{ type || 'lainnya' }}</span>
                      </label>
                    </div>
                    <div v-if="!vars.discountPercentage">
                      <validation-provider
                        #default="{ errors }"
                        name="Persentase"
                        rules="required|numeric|max_value:100"
                      >
                        <b-form-input
                          id="persentase_diskon"
                          v-model="models.discountPercentage"
                          :disabled="isDetailPage"
                          type="number"
                          max="100"
                          :state="errors.length > 0 ? false:null"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                  </b-col>
                </b-row>
                <!-- //////////////// -->
              </div>

              <!-- Field Nominal Diskon -->
              <b-row
                v-if="models.promoType !== 3"
                class="my-1"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="nominal_diskon"
                  >{{ discount_nominal_text }} (Rp)</label>
                </b-col>
                <b-col sm="8">
                  <div class="col-sm-12 mb-1 d-flex justify-content-between">
                    <label
                      v-for="type, index in masters.discount_amounts"
                      :key="index"
                      class="radio-inline"
                    >
                      <input
                        v-model="vars.discount_amount_num"
                        type="radio"
                        :value="type"
                        name="discount_amount"
                      >
                      <span class="font-weight-bold text-capitalize">{{ type ? parseFloat(type).toLocaleString().replace(/[.,]/g, m => m === '.' ? ',' : '.') : 'Lainnya' }}</span>
                    </label>
                  </div>
                  <div v-if="!vars.discount_amount_num">
                    <validation-provider
                      #default="{ errors }"
                      :name="discount_nominal_text"
                      rules="required|numeric"
                    >
                      <b-form-input
                        id="nominal_diskon"
                        v-model="maxNomDiscount"
                        :disabled="isDetailPage"
                        type="number"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                </b-col>
              </b-row>
              <!-- //////////////// -->

              <!-- Field Tanggal Berlaku -->
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="start-date"
                  >Tanggal Berlaku</label>
                </b-col>
                <b-col sm="8">
                  <b-row class="justify-content-between">
                    <b-col sm="6">
                      <validation-provider
                        #default="{ errors }"
                        name="Tanggal mulai"
                        rules="required"
                      >
                        <input
                          id="start-date"
                          v-model="models.startDate"
                          class="form-control"
                          type="datetime-local"
                          name="start-date"
                        >
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col sm="6">
                      <validation-provider
                        #default="{ errors }"
                        name="Tanggal berakhir"
                        rules="required"
                      >
                        <input
                          id="end-date"
                          v-model="models.endDate"
                          :disabled="!models.startDate"
                          :min="models.startDate"
                          class="form-control"
                          type="datetime-local"
                          name="end-date"
                        >
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <!-- //////////////// -->

              <!-- Kuota -->
              <b-row
                class="my-1"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="kuota"
                  >Kuota</label>
                </b-col>
                <b-col sm="8">
                  <div class="col-sm-7 mb-1 d-flex justify-content-between">
                    <label class="radio-inline">
                      <input
                        v-model="vars.kuotaType"
                        type="radio"
                        :value="true"
                        name="minimal_pembelian"
                      >
                      <span class="font-weight-bold">Ada</span>
                    </label>
                    <label class="radio-inline">
                      <input
                        v-model="vars.kuotaType"
                        type="radio"
                        :value="false"
                        name="minimal_pembelian"
                      >
                      <span class="font-weight-bold">Tidak Ada</span>
                    </label>
                  </div>
                  <div
                    v-if="vars.kuotaType"
                    class="d-flex align-items-baseline"
                  >
                    <div style="flex: auto; margin-right: 5px">
                      <validation-provider
                        #default="{ errors }"
                        name="Nama promo"
                        rules="required|numeric"
                      >
                        <b-form-input
                          id="kuota"
                          v-model="models.quota"
                          :disabled="isDetailPage"
                          type="number"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                    <p>Pengguna</p>
                  </div>
                </b-col>
              </b-row>
              <!-- //////////////// -->

              <!-- Status -->
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="4">
                  <label
                    class="h5"
                    for="status"
                  >Status</label>
                </b-col>
                <b-col sm="5">
                  <div class="col-sm-12 d-flex justify-content-between">
                    <label class="radio-inline">
                      <input
                        v-model="models.status"
                        type="radio"
                        :value="true"
                        name="status"
                      >
                      <span class="font-weight-bold badge badge-success">Aktif</span>
                    </label>
                    <label class="radio-inline">
                      <input
                        v-model="models.status"
                        type="radio"
                        :value="false"
                        name="status"
                      >
                      <span class="font-weight-bold badge badge-danger">Tidak Ada</span>
                    </label>
                  </div>
                </b-col>
              </b-row>
              <!-- //////////////// -->
            </b-col>
          </b-row>
          <div class="d-flex justify-content-center justify-content-md-end mt-2">

            <b-button
              type="button"
              variant="light"
              class="btn-min-width rounded"
              @click="$router.push(`${config.uri}/${$route.params.id}`)"
            >
              {{ isDetailPage ? 'Kembali' : 'Batal' }}
            </b-button>
            <b-button
              v-if="!isDetailPage"
              type="submit"
              variant="primary"
              class="btn-min-width rounded ml-1"
            >
              Simpan
            </b-button>
          </div>
        </b-card>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select'
import api from '@/utils/api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, maxValue } from '@validations'
import {
  BCard, BRow, BCol, BButton, BFormInput,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'FormPromo',
  metaInfo: {
    title: 'Form Promo',
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      config: {
        uri: `/${this.$route.meta.link}`,
      },
      models: {
        kfsId: this.$route.params.id,
        name: '',
        voucherCode: '',
        promoType: 1,
        minimumPayment: null,
        discountPrice: null,
        discountPercentage: null,
        discountPercentageMax: null,
        startDate: null,
        endDate: null,
        quota: null,
        status: true,
        allTargets: false,
        products: [],
      },
      vars: {
        queryProduct: '',
        minimumPaymentType: true,
        discount_type: 'Harga Setelah Diskon',
        kuotaType: true,
        discountPercentage: 15,
        discount_amount_num: 15000,
      },
      masters: {
        products: [],
        promo_types: [
          {
            text: 'Nominal',
            value: 1,
          },
          {
            text: 'Persentase',
            value: 2,
          },
          {
            text: 'Produk',
            value: 3,
          },
        ],
        discount_amounts: [15000, 20000, 25000, null],
        discount_percentages: [15, 20, 25, null],
      },
      // Functions
      required,
      maxValue,
    }
  },
  computed: {
    isDetailPage() {
      return this.$route.name === 'detail-promo'
    },
    discount_nominal_text() {
      return this.models.promoType === 1 ? 'Nominal Diskon' : 'Maksimal Diskon'
    },
    startDate() {
      return this.models.startDate
    },
    maxNomDiscount: {
      get() {
        if (this.models.promoType === 1) {
          return this.models.discountPrice
        }
        return this.models.discountPercentageMax
      },
      set(value) {
        if (this.models.promoType === 1) {
          this.models.discountPercentageMax = ''
          this.models.discountPrice = value
        } else {
          this.models.discountPrice = ''
          this.models.discountPercentageMax = value
        }
      },
    },
    showDiscountPercentage() {
      if (this.models.promoType === 3) {
        return Boolean(this.vars.discount_type === 'Presentase %')
      }

      return Boolean(this.models.promoType !== 1)
    },
    discount_types() {
      if (this.models.promoType === 3 && this.models.products.length > 1) {
        return ['Presentase %']
      }
      return ['Harga Setelah Diskon', 'Presentase %']
    },
  },
  watch: {
    'models.products': function () {
      if (this.models.products.length > 1) {
        this.vars.discount_type = 'Presentase %'
      }
    },
  },
  created() {
    this.getListOption()

    this.$nextTick(() => {
      this.$watch('startDate', () => {
        if (this.moment(this.models.startDate).isAfter(this.models.endDate)) {
          this.models.endDate = this.models.startDate
        }
      })
    })
  },
  methods: {
    getById() {
      if (this.$route.params.idPromo) {
        this.$axios.get(`${api.kfsPromotion}/${this.$route.params.idPromo}`)
          .then(res => {
            const { data } = res.data
            this.models.name = data.name
            this.models.voucherCode = data.voucherCode
            this.models.promoType = data.promoType
            this.models.minimumPayment = data.minimumPayment
            this.models.discountPrice = data.discountPrice
            this.models.discountPercentage = this.vars.discountPercentage = data.discountPercentage
            this.models.discountPercentageMax = Number(data.discountPercentageMax)
            this.models.startDate = this.moment(data.startDate).format('YYYY-MM-DDTHH:mm')
            this.models.endDate = this.moment(data.endDate).format('YYYY-MM-DDTHH:mm')
            this.models.quota = data.quota
            this.models.status = data.status

            data.products.forEach(product => {
              if (!this.masters.products.map(prod => prod.id).includes(product.accu_product_id)) {
                this.$axios.get(`${api.product}/${product.accu_product_id}`)
                  .then(response => {
                    const prod = response.data.data
                    this.masters.products.push(prod)
                  }).catch(() => this.$router.push({
                    name: 'page404',
                  }))
              }
              this.models.products.push(product.accu_product_id)
            })

            if (data.minimumPayment === 0) {
              this.vars.minimumPaymentType = false
            }
            if (!data.quota) {
              this.vars.kuotaType = false
            }

            this.vars.discount_amount_num = data.promoType === 1 ? Number(data.discountPrice) : Number(data.discountPercentageMax)

            if (!this.masters.discount_percentages.includes(data.discountPercentage)) {
              this.vars.discountPercentage = null
            }
            if (!this.masters.discount_amounts.includes(Number(data.discountPercentageMax))) {
              this.vars.discount_amount_num = null
            }
            if (data.promoType === 3 && data.discountPercentage) {
              this.vars.discount_type = 'Presentase %'
            }
          }).catch(() => this.$router.push({
            name: 'page404',
          }))
      }
    },

    async getListOption() {
      const getProducts = this.$axios.get(api.list_products)
      Promise.all([getProducts]).then(res => {
        this.masters.products = [...res[0].data.data]

        this.getById()
      })
    },

    debounceSearch(value) {
      if (value !== '') {
        this.vars.queryProduct = value
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {
          const response = await this.handleSearchOption(this.vars.queryProduct)
          this.masters.products = [...response]
        }, 500)
      }
    },
    async handleSearchOption(query) {
      const res = await this.$axios.get(`${api.list_products}?search=${query}`)
      return res.data.data
    },

    // Save Process
    save() {
      this.$refs.formPromo.validate().then(async success => {
        if (success) {
          let request = ''
          let message = 'create'

          this.models.minimumPayment = this.vars.minimumPaymentType ? this.models.minimumPayment : 0
          this.models.quota = this.vars.kuotaType ? this.models.quota : null
          this.models.discountPercentage = this.vars.discountPercentage || this.models.discountPercentage

          if (this.models.promoType === 1) {
            if (this.vars.discount_amount_num) {
              this.models.discountPrice = this.vars.discount_amount_num
            }
            this.models.discountPercentage = null
            this.models.discountPercentageMax = null
          } else if (this.models.promoType === 2) {
            if (this.vars.discount_amount_num) {
              this.models.discountPercentageMax = this.vars.discount_amount_num
            }
            this.models.discountPrice = null
          }
          if (this.models.promoType === 3) {
            if (this.vars.discount_type === 'Harga Setelah Diskon') {
              this.models.discountPercentage = null
            } else if (this.vars.discount_type === 'Presentase %') {
              this.models.discountPrice = null
            }
          }
          if (!this.$route.params.idPromo) {
            request = this.$axios.post(api.kfsPromotion, this.models)
          } else {
            message = 'update'
            request = this.$axios.put(`${api.kfsPromotion}/${this.$route.params.idPromo}`, this.models)
          }
          request
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Success ${message} KFS Promo`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$router.push({ name: 'detail-kfs', params: { id: this.$route.params.id, defaultActive: 'promo' } })
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        } else {
          window.scrollTo(0, 0)
        }
      })
    },
  },
}
</script>

<style>
.order-1 {
  order: 1;
}
.order-2{
  order: 2;
}
</style>
